import { DataGrid, Heading } from '@get-e/react-components';
import { Switch } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GridRowId } from '@mui/x-data-grid';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import moment from 'moment';
import { FC, useEffect, useMemo, useRef, useState } from 'react';

import { FilghtToDisplay } from './api/types';
import { useToBook } from './api/useToBook';
import { getColumnsToBook, mapToBookRow } from './data';
import { useDataGridStyles } from './styles/useDataGridStyles';
import UpdateModal from './UpdateModal';

interface ToBookProps {
    onUpdate: (toBook: number) => void;
    active: boolean;
}

const useStyles = makeStyles(() => ({
    header: {
        display: 'flex',
        padding: '1rem 0.625rem',
        alignItems: 'center',
        border: '1px solid #e0e0e0',
        borderBottom: 'none',

        '& h2': {
            marginBottom: 0,
        },
    },
    quickFilterWrapper: {
        marginLeft: 'auto',
    },
}));

const initialState: GridInitialStateCommunity = {
    sorting: {
        sortModel: [{ field: 'date', sort: 'asc' }],
    },
    filter: {
        filterModel: {
            items: [
                {
                    columnField: 'airline',
                    operatorValue: 'is',
                },
            ],
        },
    },
};

const ToBookPanel: FC<ToBookProps> = ({ onUpdate, active }) => {
    const classes = useStyles();
    const dataGridClasses = useDataGridStyles();
    const [openModal, setOpenModal] = useState<GridRowId | null>(null);
    const [isPublishedRosterOnly, setIsPublishedRosterOnly] = useState(false);
    const [toBookRows, setToBookRows] = useState<FilghtToDisplay[]>([]);
    const [toBookRowsFiltered, setToBookRowsFiltered] = useState<FilghtToDisplay[]>([]);
    const isQuickFilterInitialized = useRef(false);
    const onUpdateTimeout = useRef<NodeJS.Timeout | null>(null);

    const { data: toBookData = [], isFetching: isFetchingToBook, refetch } = useToBook();

    const ticketData = useMemo(() => {
        if (toBookData) {
            return toBookData?.find(ticket => ticket.hash === openModal);
        }
    }, [openModal, toBookData]);

    useEffect(() => {
        if (!isFetchingToBook) {
            setToBookRows(toBookData.length > 0 ? mapToBookRow(toBookData) : []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetchingToBook, toBookData.length]);

    useEffect(() => {
        if (toBookRows.length && !isQuickFilterInitialized.current) {
            isQuickFilterInitialized.current = true;
            handlePublishedRosterOnly(false);
        } else {
            handlePublishedRosterOnly(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toBookRows.length]);

    useEffect(() => {
        clearTimeout(onUpdateTimeout.current as NodeJS.Timeout);
        onUpdateTimeout.current = setTimeout(() => {

            if (toBookRows.length) {
                onUpdate?.(isPublishedRosterOnly ? toBookRowsFiltered?.length : toBookRows.length);
            }
        }, 500);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPublishedRosterOnly, toBookRows.length, toBookRowsFiltered.length]);

    const handleCloseModal = () => setOpenModal(null);

    const handleUpdate = () => {
        refetch();
    };

    const handlePublishedRosterOnly = (isRefetch: boolean) => {
        const showPublishedRosters = isRefetch ? isPublishedRosterOnly : !isPublishedRosterOnly;

        setIsPublishedRosterOnly(showPublishedRosters);

        if (showPublishedRosters) {
            const utcDateTime = moment.utc();
            const currentUtcDay = utcDateTime.date();
            const currentUtcMonth = utcDateTime.month();
            const nextUtcMonth = utcDateTime.add(1, 'M').month();

            let publishedRosters: FilghtToDisplay[] = [];

            if (currentUtcDay < 15) {
                publishedRosters = toBookRows.filter(booking => {
                    const bookingUtcMonth = moment(booking.date).month();

                    return bookingUtcMonth === currentUtcMonth;
                });
            } else {
                publishedRosters = toBookRows.filter(booking => {
                    const bookingUtcMonth = moment(booking.date).month();

                    return bookingUtcMonth === currentUtcMonth || bookingUtcMonth === nextUtcMonth;
                });
            }

            setToBookRowsFiltered(publishedRosters);
        }
    };

    return (
        <div className={dataGridClasses.dataGrid}>
            {active && (
                <>
                    <div className={classes.header}>
                        <Heading level={2}>Flights to book</Heading>
                        <div className={classes.quickFilterWrapper}>
                            <span>Published roster only</span>
                            <Switch
                                checked={isPublishedRosterOnly}
                                onChange={() => handlePublishedRosterOnly(false)}
                                name="loading"
                                color="primary"
                                disabled={isFetchingToBook}
                            />
                        </div>
                    </div>
                    <DataGrid
                        loading={isFetchingToBook}
                        rows={isPublishedRosterOnly ? toBookRowsFiltered : toBookRows}
                        columns={getColumnsToBook(toBookData)}
                        pageSize={20}
                        rowsPerPageOption={[20]}
                        onRowClick={row => setOpenModal(row.id)}
                        autoHeight
                        enableFilters
                        noRows="No flights to book"
                        initialState={initialState}
                    />
                </>
            )}
            {openModal && (
                <UpdateModal open={openModal} onClose={handleCloseModal} onUpdate={handleUpdate} ticketData={ticketData} />
            )}
        </div>
    );
};

export default ToBookPanel;
